<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
        <asked-questions/>
    </section>
</template>
<script>
import AskedQuestions from '@/components/activitiesSurveys/AskedQuestions'
import BreadCrumb from '@/components/BreadCrumb'
export default {
  name: 'PublicCouncilsFAQ',
  components: {
    AskedQuestions,
    BreadCrumb
  },
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественные советы',
          path: '/public_councils'
        },
        {
          title: 'Часто задаваемые вопросы'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
