<template>
    <div class="auth">
        <form @submit.prevent="submitForm" ref="faq">
            <p class="auth__header">Задайте свой вопрос</p>
            <p class="auth__title">ФИО<span>*</span></p>
            <input v-model="form.fullName" class="input auth__input" required name="fullName" type="text">
            <p class="auth__title">E-mail<span>*</span></p>
            <input v-model="form.email" class="input auth__input" required name="email" type="email">
            <p class="auth__title">Тема вопроса<span>*</span></p>
            <input v-model="form.topic" class="input auth__input" required name="question" type="text">
            <p class="auth__title">Ваш вопрос<span>*</span></p>
            <input v-model="form.question" class="input auth__input" required name="text" type="text">
            <!--  -->
            <div class="checkbox_block">
                <input class="custom-checkbox" type="checkbox" id="assent" v-model="form.checkboxValue" :class="{'box__active': form.checkboxValue}">
                <label for="assent"></label>
                <p>Согласие обработки персональных данных “в соответствии с требованиями Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных»”</p>
            </div>
            <!--  -->
            <button class="btn auth__button" type="submit" :disabled="!form.checkboxValue">Отправить</button>
        </form>
        <div @click="close()" class="auth__button-close"></div>
    </div>
</template>

<script>

export default {
  name: 'QuestionSubmissionForm',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      form: {
        fullName: '',
        email: '',
        topic: '',
        question: '',
        checkboxValue: false
      }
    }
  },
  methods: {
    close () {
      this.$emit('click')
    },
    login () {
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     body: `email=${this.form.login}&password=${this.form.password}`
    //   }
    //   fetch(`${this.$store.getters.getUrlApi}api/auth/login`, requestOptions)
    //     .then(response => response.json())
    //     .then(data => {
    //       this.$store.dispatch('saveToken', data.data)
    //       this.$store.dispatch('fetchUser')
    //       this.close()
    //     })
    },
    async submitForm () {
      const formData = new FormData(this.$refs.faq)
      await fetch(`${this.$store.getters.getUrlApi}api/mail/faq`, {
        method: 'POST',
        body: formData
      })
      this.close()
      this.$store.commit('setMessage', {
        type: 'success',
        text: 'Спасибо за ваш вопрос, мы с вами свяжемся'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .auth {
    position: relative;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: .19rem;
    width: 90%;
    max-width: 28.13rem;
    margin: auto;

    &__header {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #1F3245;
      padding-bottom: 0.625rem;
      margin-bottom: 1.25rem;
    }
    &__title {
      font-weight: normal;
      font-size: 0.88rem;
      line-height: 1.38rem;
      color: #1F3245;
      margin: 0.75rem 0;

      span{ color:#EB494E; }
    }
    &__input {
      background: #EEF4FA;
      border-radius: 3px;
    }
    &__button {
      border: none;
      padding: 0.625rem 1.625rem;
      line-height: 143%;
      background: #246CB7;
      border-radius: 3px;
      color: #FFFFFF;
      margin-top: 1.5rem;
    }
    &__button-close {
        width: 2.38rem;
        height: 2.38rem;
        position: absolute;
        top: -1rem;
        right: -1rem;
        z-index: 99999;

        background:  #ffffff url('../../assets/icons/close.svg') center center no-repeat;
        background-size: .63rem;
        border-radius: 50%;
        overflow: hidden;
        color: transparent;
        cursor: pointer;
    }

  }
  .checkbox_block{
    display: flex;
    margin-top: 1.38rem;
    p{
        font-size: .75rem;
        line-height: 1.125rem;
        color: #1F3245;
        margin-left: .69rem;
    }
    label{
        margin-bottom: 0;
    }
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: auto !important;
  }
  .custom-checkbox + label::before {
    content: '';
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: .19rem;
    background-color: #DDEDFE;
    cursor: pointer;
  }
  .box__active + label::before{
    background: #DDEDFE url("../../assets/icons/check.svg") center center no-repeat;
    background-size: 80%;
}
</style>
