<template>
  <article class="accordion-block" :class="{'active': show}">
    <header v-on:click="toggle">
      <p>{{ content.question }}</p>
      <i class="accordion-block__details" :class="{'active' : show}"></i>
    </header>
    <transition name="accordion">
      <div class="accordion-block__body" v-show="show">
        <div class="accordion-block__inner ckEditor-html" v-html="content.answer"></div>
      </div>
    </transition>
  </article>

</template>

<script>
export default {
  name: 'AccordionQuestion',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggle: function () {
      this.show = !this.show
    }
  }
}
</script>

<style scoped lang="scss">
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
  .accordion-block {
    padding: 1.25rem 1.875rem;
    border: 1px solid #E5E8EE;
    border-radius: 8px;
    margin-bottom: 1.875rem;
    font-size: .875rem;
    line-height: 1.57;

    &.active {
      box-shadow: 0 15px 25px rgba(189, 205, 221, 0.35);
    }

    header {
      color: #1F3245;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & p {
        margin-right: 5rem;
      }

      & i {
        border: .1875rem solid #9D9D9D;
        border-width: 0 .1875rem .1875rem 0;
        border-radius: .0625rem;
        display: inline-block;
        padding: .1875rem;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: .0625rem;
      }

      .active {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

    }

    &__body {
      margin-top: 1.875rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      :nth-child(n):not li {
        margin-bottom: 1.5rem;
      }

      :nth-child(n) {
        margin-bottom: .75rem;
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    &__inner {
      color: rgba(23, 23, 23, 0.8);
      width: 85%;
      padding-top: 1.25rem;

      & ::v-deep {
        li {
          line-height: 1.57;
          color: #246CB7;
          cursor: pointer;
          list-style-type: disc;
          padding: 0;
          margin-left: 1rem;
        }
      }
    }

  }

  @media screen and (max-width: 768px) {
    .accordion-block {
      &__inner {
        width: 96.78%;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .accordion-block {
      padding: 1.25rem 1.38rem;

      &__inner {
        width: 92.78%;
      }

      header {
        p {
          margin-right: .125rem;
        }
      }
    }
  }
</style>
