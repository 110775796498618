<template>
    <section class="structure-main">
        <div class="white-container">
            <h5>Часто задаваемые вопросы</h5>
            <accordion v-for="(item, i) in getAllQuestions" :content="item" :key="`accordion${i}`"/>
        </div>
        <div class="white-container">
            <figure></figure>
            <h5>Не нашли ответ на свой вопрос?</h5>
            <p>Задать вопрос может любой гражданин РФ, который руководит действующим социальным проектом</p>
            <button @click="isActive = true">Задать вопрос</button>
        </div>
        <div v-show="!!isActive" class="popup-form">
            <question-submission-form @click="isActive = false"/>
        </div>
    </section>
</template>
<script>
import Accordion from '@/components/activitiesSurveys/AccordionQuestion'
import QuestionSubmissionForm from '@/components/activitiesSurveys/QuestionSubmissionForm'
export default {
  name: 'AskedQuestions',
  components: {
    Accordion,
    QuestionSubmissionForm
  },
  data () {
    return {
      isActive: false
    }
  },
  mounted () {
    this.$store.dispatch('setAllQuestions')
  },
  computed: {
    getAllQuestions () {
      return this.$store.getters.getAllQuestions
    }
  }
}
</script>
<style lang="scss" scoped>
.structure-main{
    display: flex;
    justify-content: space-between;
    width: 90rem;
    margin: 0 auto;
    & .white-container:nth-child(1){
        width: calc(100% - 26.44rem - 2.19rem);
        margin: 0;
        padding: 2.5rem 2.94rem;

        h5{ margin-bottom: 2rem; }
    }
    & .white-container:nth-child(2){
        width: 26.44rem;
        height: 100%;
        margin: 0 0 0 2.19rem;
        padding: 1.88rem;

        p{
            font-size: .75rem;
            line-height: 1.125rem;
            color: #1F3245;
            margin: 0.5rem 0 1.38rem 0;

        }
    }
}
.white-container{
    display: flex;
    flex-direction: column;
}
h5{
    font-weight: 600;
    font-size: 1.13rem;
    line-height: 1.5rem;
    color: #1F3245;
}
.accordion-block:last-child{
    margin-bottom: 0;
}
figure{
    width: 21.69rem;
    height: 8.88rem;
    background: url('../../assets/bg/many_questions.png');
    background-size: cover;
    margin-bottom: 1.88rem;
}
button{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: center;
    padding: .5rem 0 .44rem 0;
    color: #FFFFFF;
    background: #246CB7;
    border: 0;
    border-radius: .19rem;
}
.popup-form{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex: 1;
    background: rgba(32, 51, 70, 0.33);
    z-index: 99;
}
@media screen and (max-width: 768px){
  .structure-main{
    width: auto;
    margin: 0 .75rem;
    flex-direction: column;
    & .white-container:nth-child(2){
        width: 100%;
        margin: 0;
        padding: 2.23rem 1.25rem;
        figure{
          margin: 0 auto 1.88rem auto;
        }
    }
    & .white-container:nth-child(1){
        width: 100%;
        margin: 0 0 1rem 0;
        padding: 2.23rem 1.25rem;
    }
    // & .white-container:nth-child(2){ display: none; }
  }
}
@media screen and (max-width: 420px){
  .structure-main{
    width: 100%;
    margin: 0;
    & .white-container:nth-child(1){
        padding: 1.38rem 1rem;
    }
  }
}

</style>
